import "styles/pages/page-spa.scss";

import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import TextWithSymbol from "components/TextWithSymbol";
import ImageWithText from "../components/ImageWithText";
import Breadcrumbs from "../components/Breadcrumbs";

const SpaPage = ({ pageContext }) => {
	const pageSpa = pageContext.pageContent;
	return (
		<Layout headerColor="#6AD044">
			<Seo
				title={
					pageContext.seo.title
						? pageContext.seo.title
						: pageContext.title
				}
				description={
					pageContext.seo.metaDesc && pageContext.seo.metaDesc
				}
			/>
			<SubpageHeader
				image={require("assets/images/spa-header-bg.jpg").default}
			/>
			<Breadcrumbs currentLocationLabel="Tajskie spa" />
			<section className="spa-stress">
				<div className="container">
					<h2 className="section-title">{pageSpa.spaTitle}</h2>
					<div className="row">
						<div className="col-md-8 offset-md-1">
							<div
								className="spa-stress__text"
								dangerouslySetInnerHTML={{
									__html: pageSpa.spaDesc,
								}}
							></div>
						</div>
					</div>
				</div>
			</section>
			<section className="spa-massage">
				<div className="container">
					<div className="row flex-lg-row flex-column-reverse">
						<div className="col-xl-7 col-lg-5">
							<img
								src={pageSpa.thaiImg1?.sourceUrl}
								alt=""
								className="img-fluid spa-massage__img spa-massage__img--1"
							/>
							<div className="row">
								<div className="col-md-5">
									<img
										src={pageSpa.thaiImg2?.sourceUrl}
										alt=""
										className="img-fluid spa-massage__img spa-massage__img--2"
									/>
								</div>
								<div className="col-md-7">
									<img
										src={pageSpa.thaiImg3?.sourceUrl}
										alt=""
										className="img-fluid spa-massage__img spa-massage__img--3"
									/>
								</div>
							</div>
						</div>
						<div className="col-xl-5 col-lg-7">
							<div className="spa-massage__text-container">
								<h2
									className="section-title"
									dangerouslySetInnerHTML={{
										__html: pageSpa.thaiTitle,
									}}
								></h2>
								<div className="spa-massage__text">
									<div
										className="spa-message__text-specific"
										dangerouslySetInnerHTML={{
											__html: pageSpa.thaiDesc1,
										}}
									></div>
									{pageSpa.thaiTechnics.map((node, index) => {
										return (
											<TextWithSymbol
												title={node.title}
												className="spa-message__text"
												key={index}
											/>
										);
									})}

									<div
										className="spa-massage__text-specific"
										dangerouslySetInnerHTML={{
											__html: pageSpa.thaiDesc2,
										}}
									/>
									<div className="spa-massage__text spa-massage__text--mobile">
										<br />
										<br />
										Oprócz tradycyjnego tajskiego masażu
										możesz także skorzystać z masażu w innym
										stylu lub zabiegu pielęgnacyjnego tj.
										masaż aromaterapeutyczny (masaż
										olejkami), refleksologia, body wrap,
										pielęgnacja ciała, czy peeling ciała.
									</div>
								</div>
							</div>
						</div>
						<div className="col-12">
							<div className="spa-massage__text spa-massage__text--desktop">
								<br />
								<br />
								Oprócz tradycyjnego tajskiego masażu możesz
								także skorzystać z masażu w innym stylu lub
								zabiegu pielęgnacyjnego tj. masaż
								aromaterapeutyczny (masaż olejkami),
								refleksologia, body wrap, pielęgnacja ciała, czy
								peeling ciała.
							</div>
						</div>
					</div>
				</div>
			</section>
			<section className="spa-tiles">
				<div className="spa-tiles__main-wrapper">
					<div className="container">
						<div className="row">
							{pageSpa.thaiCategories.map((node, index) => {
								return (
									<div className="col-md-6">
										<ImageWithText
											title={node.title}
											text={node.desc}
											image={node.img?.sourceUrl}
											textPadding={150}
											marginBottom={150}
										/>
									</div>
								);
							})}
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default SpaPage;
